import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>INLAYS AND ONLAYS</h2>
      <h3>What are they?</h3>
      <p>You can sometimes have inlays or onlays instead of fillings to repair damaged teeth. Fillings are soft to begin with and are moulded in the tooth which has been shaped to take and hold the filling. Inlays and onlays are made outside the mouth, usually in a dental laboratory, and then glued to the tooth in the surgery.</p>
      <p>An inlay sits in a hole in the tooth. An onlay sits on the tooth and builds up its shape. Inlays and onlays can be made in tooth-coloured porcelain, gold or a white filling material called composite.  Different materials are suitable for different parts of the mouth and different parts of the teeth.</p>
      <h3>What will my dentist do?</h3>
      <p>Your dentist will:</p>
      <ul>
        <li>normally give you a local anaesthetic to numb the area</li>
        <li>remove any old filling material and any decayed or damaged tooth</li>
        <li>use a putty-like material to record the shape of the tooth being repaired and the teeth around it -this is called taking an "impression"</li>
        <li>give the impression to a dental technician to make  the inlay or onlay</li>
        <li>use a temporary filling to protect  the tooth that is being repaired while you wait for the inlay or onlay  to be made</li>
        <li>glue the inlay or onlay  in place  on your next visit - the dentist may make  small adjustments, so that the tooth is comfortable to bite on.</li>
      </ul>
      <h3>What are the benefits?</h3>
      <p>Inlays and onlays are strong  and can last longer than fillings</p>
      <p>They are especially suitable for the chewing surfaces of back teeth and for large repairs to front  teeth,  where  it can be difficult to make  a white  filling  look  natural.</p>
      <p>Your dentist can match the colour to your  other  teeth.</p>
    </LightboxLayout>
  )
}

export default Page